@import "../../scss/variables.scss";
header {
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    top: 0;
    width: 100%;
    z-index: 999;
    .navbar-brand {
        @media only screen and (max-width: 414px) {
            margin-right: 0;
        }
    }
    .top-header {
        font-family: "Poppins", sans-serif;
        background-color: #262626;
        padding: 12px 20px;
        @media only screen and (max-width: 767px) {
            padding: 8px 10px;
        }
        .header-icon {
            // display: none;
            @media only screen and (max-width: 767px) {
                display: block;
                font-size: 16px;
                padding: 0 7px;
            }
            img {
                height: 16px;
                margin-right: 6px;
            }
        }
        .mobile-d-none {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }
    .bottom-header {
        background-color: #fff;
        padding: 0px 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 1100px) {
            padding: 0px 15px;
        }
        @media only screen and (max-width: 992px) {
            padding: 0px 15px;
        }
        @media only screen and (max-width: 767px) {
            padding: 0px 15px;
        }
        .header-logo {
            display: flex;
            align-items: center;
            max-width: 85px;
            @media only screen and (max-width: 992px) {
                justify-content: space-between;
                max-width: 75px;
            }
            @media only screen and (max-width: 767px) {
                max-width: 50px;
            }
            .header-logo {
                img {
                    @media only screen and (max-width: 992px) {
                        width: 70px;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 50px;
                    }
                }
            }
        }
        .bottom-header-right {
            display: flex;
            margin-right: 0px;
            align-items: center;
            padding-right: 15px;
            @media only screen and (max-width: 767px) {
                margin-right: 100px;
            }
            @media only screen and (max-width: 414px) {
                margin-right: 70px;
            }
        }
        .navbar-toggler {
            border: none;
            padding: 0px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

header .bottom-header nav .nav-link.active {
    &::after {
        background-color: $body-color;
    }
}

header .bottom-header nav .nav-link:hover {
    color: $primary-color;
    &::after {
        background-color: $body-color;
    }
}

.ant-popover .ant-popover-inner {
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ant-popover-title {
    font-size: 18px;
    padding: 8px 15px !important;
    margin-bottom: 0 !important;
    display: none;
}

.dropdown-custom-menu {
    font-size: 15px !important;
    color: $body-color;
    padding-bottom: 0;
    padding: 10px 20px !important;
    transition: 0.3s all;
    font-family: $font-monteserrat !important;
    &:hover {
        color: $bg-color-white !important;
        background-color: $primary-color !important;
    }
}

.ant-popover-inner-content {
    padding: 10px 0px;
    span {
        padding: 8px 15px 20px !important;
        font-size: 14px !important;
        font-weight: normal !important;
    }
}

.ant-popover-content {
    .ant-popover-arrow {
        display: none !important;
    }
}

.toggle-custom {
    width: 100% !important;
    text-align: inherit !important;
    display: flex !important;
    justify-content: space-between !important;
    font-style: normal !important;
    font-weight: 500 !important;
    border: none !important;
    font-size: var(--bs-nav-link-font-size) !important;
    border-bottom: 1px solid #dadada !important;
    padding: 10px 15px !important;
    border-radius: inherit !important;
}

.toggle-custom :focus {
    box-shadow: none !important;
}

.dropdown-menu.show {
    border-radius: 0px !important;
    padding: 0px !important;
    border: none !important;
    font-size: 13px !important;
    margin-left: 13px !important;
    font-weight: bolder !important;
}

.header-badge {
    max-width: 130px;
    position: absolute;
    right: 15px;
    top: 5px;
    @media only screen and (max-width: 1200px) {
        max-width: 100px;
        position: absolute;
        right: 10px;
        top: 15px;
    }
    @media only screen and (max-width: 991px) {
        max-width: 100px;
        position: absolute;
        right: 60px;
        top: 15px;
    }
    @media only screen and (max-width: 600px) {
        max-width: 50px;
        top: 10px;
    }
    @media only screen and (max-width: 414px) {
        right: 50px;
    }
}

.discount-offer {
    margin-right: 25px;
    max-width: 300px;
    @media only screen and (max-width: 1500px) {
        max-width: 200px;
    }
    @media only screen and (max-width: 1200px) {
        display: none;
    }
}

.discount-offer-mobile {
    @media only screen and (max-width: 1200px) {
        max-width: 100%;
        margin: 15px;
    }
}