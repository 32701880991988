.tenyearvisa {
      width: 100%;
        display: flex;
       flex-direction: column;

      &-breadcrum {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:  flex-end;
        flex-direction: column;
        height: 25rem;

        @media (max-width: 768px) {
          height: 20rem;
        }



        h2 {
          margin: 0;
          color: white;
          font-size: 4rem;
          padding-bottom: 5rem;



          @media (max-width: 500px) {
            font-size: 3rem;
          }

          @media (max-width: 375px) {
            font-size: 2rem;
          }
        }

        h3 {
          margin: 0;
          color: white;
          font-size: 4rem;
          padding-bottom: 1.5rem;



          @media (max-width: 500px) {
            font-size: 3rem;
          }

          @media (max-width: 375px) {
            font-size: 2rem;
          }
        }
      }
      &-cardsdiv {
        max-width: 1024px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 0px auto;
        margin-top: -3.5rem;
        @media (max-width: 1024px) {
            padding: 20px;
        }
        @media (max-width: 768px) {
          margin-top: -3.8rem;
        }

          &-cardContent {
              width: 100%;
              display: flex;
              gap: 12px;

            @media (max-width: 500px) {
              flex-direction: column;
              align-items: center;
              gap: 20px;
            }

            &-carditems {
              height: 22rem;
              width: 25%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              align-items: center;
              justify-content: center;
              background: rgba(255,255,255,0.9);
              border: none;
              padding: 10px;
              //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              box-shadow: -1.00px 0.09px 8px 3px rgba(0, 0, 0, 0.32);
              @media (max-width: 768px) {
                height: 18rem;
              }
              @media (max-width: 500px) {
                width: 80%;
              }
              &-imgdiv {
                width: 5rem;
                height: 5rem;
                border-radius: 100%;
                padding: 6px 10px;
                border: 1px solid gray;
                box-shadow: 0.00px 1.00px 4px 0px rgba(0,0,0,0.6);
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;

                @media (max-width: 768px) {
                  width: 4rem;
                  height: 4rem;
                }

                //@media (max-width: 1024px) {
                //  padding: 20px;
                //}

                img {
                  width: 2.6rem;

                  @media (max-width: 768px) {
                    width: 2rem;
                  }
                }

              }


              &-headings {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-top: 12px;
                h3 {
                  margin: 0;
                  font-size: 22px;
                  text-align: center;
                  @media (max-width: 768px) {
                    font-size: 16px;
                  }
                }

                p{
                  margin: 0;
                  font-size: 16px;
                  text-align: center;

                  @media (max-width: 768px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          &-contactformdiv {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 15px;
            padding-bottom: 4rem;
            padding-top: 5rem;

            @media (max-width: 768px) {
              padding-top: 2rem;
            }
            @media (max-width: 500px) {
              flex-direction: column-reverse;
              align-items: center;
              gap: 20px;
            }

            &-leftside {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 25px;

              @media (max-width: 500px) {
                width: 100%;

              }


              h3 {
                font-size: 2.5rem;
                font-weight: bold;
                margin: 0;

                @media (max-width: 768px) {
                  font-size: 2rem;
                }
                @media (max-width: 320px) {
                  font-size: 1.8rem;
                }
              }

              p {
                font-size: 20px;
                margin: 0;
                width: 85%;
                font-weight: bold;
                @media (max-width: 500px) {
                  width: 100%;
                }

              }

              button {
                  width: 35%;
                  border-radius: 20px;
                  padding: 10px 15px;
                background: #095DC2;
                  color: #FFFFFF;

                @media (max-width: 500px) {
                  width: 40%;
                }
              }

              &-socialicons {
                display: flex;
                flex-direction: column;
                gap: 18px;
                padding-top: 2rem;

                &-heading {

                  h4 {
                    margin: 0;
                    font-size: 25px;
                  }
                }

                &-icons {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex-wrap: wrap;

                  img {
                    width: 40px;
                    cursor: pointer;
                  }
                }


              }
            }
            &-rightside {
                width: 50%;
                display: flex;
                flex-direction: column;
                box-shadow: 0.00px 1.00px 4px 0px rgba(0,0,0,0.6);
                padding: 1.5rem;
                gap: 15px;

              @media (max-width: 768px) {
                padding: 1rem;
                gap: 10px;
              }
              @media (max-width: 500px) {
                width: 100%;

              }

                  h2 {
                    text-align: center;
                    margin: 0;
                    font-weight: 700;
                    font-size: 2rem;
                    padding-bottom: 1rem;
                  }
                //&-dobulefield {
                //  display: flex;
                //  align-items: center;
                //  gap: 15px;
                //
                //  &-innerinp {
                //    width: 50%;
                //    display: flex;
                //    flex-direction: column;
                //    gap: 8px;
                //
                //    input {
                //      padding: 6px;
                //    }
                //
                //    label {
                //      font-size: 20px;
                //      font-weight: 600;
                //      margin: 0;
                //    }
                //  }
                //
                //
                //}

              &-singlefield {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                label {
                  font-size: 20px;
                  font-weight: 600;
                  margin: 0;
                }

                input {
                  padding: 6px;
                }

                textarea {
                  resize: none;
                  padding: 6px;

                }
              }

              &-btndiv {
                  width: 100%;
                  display: flex;

                button {
                  border-radius: 20px;
                  padding: 10px 30px;
                  background: #095DC2;
                  color: #FFFFFF;
                  font-weight: 600;
                  font-size: 18px;
                  @media (max-width: 768px) {
                    font-size: 16px;
                    padding: 10px 25px;
                  }
                }

              }
            }

          }

      }

}

.customqueryform {
  position: fixed;
  right: 0;
  top: 15rem;
  z-index: 200;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-specificpara{
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin: 0;
    font-size: 30px;
    cursor: pointer;
    background: #118dff;
    color: white;
    font-weight: 500;
    padding: 10px 5px;
    border-radius: 15px;
    font-family: 'Montserrat';



    @media only screen and (max-width: 1024px) {
      font-size: 28px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 27px;
    }

    @media only screen and (max-width: 500px) {
      font-size: 25px;
    }
  }
}

.customqueryform.active {
  width: 18%;

  @media only screen and (max-width: 1920px) {
    width: 25%;
  }

  @media only screen and (max-width: 1024px) {
    width: 35%;
  }

  @media only screen and (max-width: 768px) {
    width: 50%;
  }

  @media only screen and (max-width: 500px) {
    width: 80%;
  }
}