@import "../../scss/variables.scss";
.page-contactus {
    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
    .map-section {
        margin-bottom: calc(15px + (60 - 15) * ((100vw - 300px) / (1600 - 300)));
    }
    .contact-left-section {
        display: flex;
        .contact-item {
            margin-right: 45px;
        }
    }
    .contact-side-content {
        h4 {
            margin-bottom: calc(15px + (24 - 15) * ((100vw - 300px) / (1600 - 300)));
        }
        .contact-item {
            @media (max-width: 767px) {
                margin-right: 15px;
            }
            p {
                margin-bottom: 12px;
            }
        }
    }
    .contact-right-section {
        @media (max-width: 767px) {
            .contact-side-content {
                display: flex;
                flex-wrap: wrap;
                .contact-item {
                    @media (max-width: 767px) {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}