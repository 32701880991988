@import "../../scss/variables.scss";
footer {
    position: relative;
    border-radius: 48px 48px 0px 0px;
    .partners-logo {
        padding: 0px 12px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: $bg-color-white;
        border-radius: 24px;
        position: relative;
        top: 0px;
        margin-bottom: -70px;



        @media only screen and (max-width: 992px) {
            top: -30px
        }
        @media only screen and (max-width: 600px) {
            justify-content: center;
            margin-bottom: 0px;
            border-radius: 12px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
        }
        .partner-logo-img {
            padding: 45px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;



            .logo-icon {

                width:80%;


            }
            @media only screen and (max-width: 1366px) {
                padding: 55px 15px;
            }
            @media only screen and (max-width: 1100px) {
                padding: 45px 12px;
            }
            @media only screen and (max-width: 992px) {
                padding: 30px 10px;
            }
            @media only screen and (max-width: 600px) {
                margin: 0px;
                padding: 20px 8px;
                width: auto;
                display: flex;
            }
            @media only screen and (max-width: 414px) {
                margin: 0px;
                padding: 15px 8px;
            }
            img {
                @media only screen and (max-width: 992px) {
                    height: auto;
                }
            }
        }
    }
    .footer-top {
        background: linear-gradient(180deg, #2A94F5 0%, #0145CA 100%);
        padding: 30px 10px;
        border-radius: 48px 48px 0px 0px;
        padding-top: 80px;
        @media only screen and (max-width: 992px) {
            padding-top: 40px;
            border-radius: 35px 35px 0px 0px;
        }
        @media only screen and (max-width: 575px) {
            padding: 25px 10px 10px 10px;
            border-radius: 30px 30px 0px 0px;
        }
        @media only screen and (max-width: 375px) {
            border-radius: 24px 24px 0px 0px;
        }
        .footer-counter-section {
            display: flex;
            flex-wrap: wrap;
            @media only screen and (max-width: 992px) {
                justify-content: center;
            }
            .footer-counter-list {
                display: flex;
                @media only screen and (max-width: 992px) {
                    margin-bottom: 25px;
                }
                @media only screen and (max-width: 767px) {
                    margin-bottom: 15px;
                }
                .second-counter-item {
                    margin-left: 50px;
                    margin-right: 100px;
                    @media only screen and (max-width: 1300px) {
                        margin-right: 0px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-left: 25px;
                        margin-right: 10px;
                    }
                    p {
                        @media only screen and (max-width: 767px) {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        .footer-top-text-link {
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media only screen and (max-width: 992px) {
                margin-top: 30px;
            }
            @media only screen and (max-width: 575px) {
                margin-top: 0px;
            }
            .footer-top-text-item {
                max-width: 25%;
                width: 100%;
                margin: 0 -15px;
                padding: 0px 15px;
                @media only screen and (max-width: 1100px) {
                    max-width: 50%;
                }
                @media only screen and (max-width: 600px) {
                    max-width: 100%;
                }
                @media only screen and (max-width: 414px) {
                    max-width: 100%;
                }
                .footer-links-title {
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 15px;
                    }
                    &::after {
                        content: '';
                        height: 1px;
                        width: 48px;
                        border-radius: 1px;
                        background-color: #fff;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                .footer-links-left {
                    a {
                        position: relative;
                    }
                }
                .add-icon {
                    height: 25px;
                    width: 25px;
                    background: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    margin-right: 15px;
                    min-width: 25px;
                }
                .footer-desc {
                    line-height: 22px;
                }
            }
            .footer-top-text-item.second-footer-text-item {
                @media only screen and (max-width: 600px) {
                    max-width: 50% !important;
                }
                @media only screen and (max-width: 414px) {
                    max-width: 100% !important;
                }
                .footer-social-section {
                    @media only screen and (max-width: 414px) {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        align-items: center;
                    }
                    a {
                        @media only screen and (max-width: 414px) {
                            margin-right: 10px;
                            margin-bottom: 0px !important;
                        }
                    }
                    .add-icon {
                        @media only screen and (max-width: 414px) {
                            width: 15px;
                            height: 15px;
                            font-size: 8px;
                            margin-right: 6px;
                            min-width: auto;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            padding: 25px 0px 0px;
            font-size: $font-size-14;
            @media only screen and (max-width: 375px) {
                padding: 15px;
            }
            .footer-bottom-links {
                a {
                    text-decoration: underline !important;
                }
            }
        }
    }
}


.footer-top-mobile {
    background: linear-gradient(180deg, #2A94F5 0%, #0145CA 100%);
    padding: 25px 10px 10px 10px;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-headandpara {
        display: flex;
        flex-direction: column;
        gap: 12px;
            h3 {
                font-size: 20px;
                color: white;
                text-decoration: underline;
                text-underline-offset: 4px;
                margin-bottom: 0;
            }
            p {
                font-size: 13px;
                color: white;
                margin-bottom: 0;

            }
    }

    &-quicklinks {
        display: flex;
        flex-direction: column;
        gap: 13px;

        h3 {
            font-size: 20px;
            color: white;
            text-decoration: underline;
            text-underline-offset: 4px;
            margin-bottom: 0;
        }

        &-linkwrap {
            width: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;
            flex-wrap: wrap;
            gap: 15px;

            a {
                font-size: 12px;
                color: white;
                border-right: 1.5px solid white;
                padding-right: 5px;
                cursor: pointer;
                font-weight: 500;

                &:last-child {
                    border-right: none;
                }
            }
        }

    }
    &-addressandcontact {
        display: flex;
        flex-direction: column;
        gap: 13px;

        h3 {
            font-size: 20px;
            color: white;
            text-decoration: underline;
            text-underline-offset: 4px;
            margin-bottom: 0;
        }

        &-items {
            width: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;
            flex-wrap: wrap;
            gap: 15px;

            a{
                display: flex;
                align-items: center;
                gap: 8px;
                color: white;
                cursor: pointer;
                border-right: 1.5px solid white;
                padding-right: 8px;

                &:last-child {
                    border-right: none;
                }
                h6 {
                    font-size: 12px;
                    color: white;
                    margin: 0;

                }

               .add-icon {
                   background: white;
                   border-radius: 100%;
                   padding: 4px 8px;

                   i {
                       color: black;
                   }
               }
            }
        }

    }
    &-followus {
        display: flex;
        flex-direction: column;
        gap: 13px;

        h3 {
            font-size: 20px;
            color: white;
            text-decoration: underline;
            text-underline-offset: 4px;
            margin-bottom: 0;
        }

        &-items {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px 10px;

            a{
                display: flex;
                align-items: center;
                gap: 8px;
                color: white;
                cursor: pointer;
                border-right: 1.5px solid white;
                padding-right: 8px;

                &:last-child {
                    border-right: none;
                }

                h6 {
                    font-size: 12px;
                    color: white;
                    margin: 0;

                }

                .add-icon {
                    background: white;
                    border-radius: 100%;
                    padding: 2px 6px;

                    i {
                        color: black;
                    }
                }
            }
        }

    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;
        gap: 5px;
        padding-top: 10px;

        p {
            font-size: 12px;
            margin: 0;
            font-weight: 600;
            text-align: center;
        }
        }
}