@import "../../scss/variables.scss";
.page-packages {
    .page-package-detail {
        .package-services {
            padding: 0px 0px 20px!important;
            &-wrapper {
                width: 100%;
                display: flex;
                padding-top: 1rem;
                
                @media only screen and (min-width:280px) and (max-width:500px){
                        flex-direction: column;
                        gap: 20px;
                }



                &-divOneHeading {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    @media only screen and (min-width:280px) and (max-width:500px){
                        width: 100%;


                        h1 {
                            font-size: 1.2rem;
                            padding-bottom: 10px;
                        }
                       
                }
                }

                &-divTwoStickyCard {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    margin-top: -40px;
                     z-index: 9999999;
                     @media only screen and (min-width:500px) and (max-width:768px){
                        width: 55%;
                        z-index: 0;
                        margin-top:-60px;
                     }

                     @media only screen and (min-width:280px) and (max-width:500px){
                        width: 100%;
                        margin-top:0px;
                        z-index: 0;
                }
                     


                    &-mainbody {
                        width: 50%;
                        margin: 0px auto;
                        border: 1px solid #e1e1e1;
                        @media only screen and (min-width:500px) and (max-width:768px){
                           width: 70%;
                        }

                        @media only screen and (min-width:280px) and (max-width:500px){
                            width: 65%;
                           
                    }

                        &-title {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(44, 72, 122, 0.9);
                            padding: 8px;

                            h4 {
                                color: white;
                                font-size: 28px;
                            }
                        }

                        &-titleTwo {
                            width: 100%;
                            display: flex;
                            height: 10rem;
                            align-items: center;
                            justify-content: center;
                            background-color: #132F3B;
                            @media only screen and (min-width:500px) and (max-width:768px){
                                height: 8rem;
                             }
                            &-text {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                h4 {
                                    font-size: 22px;
                                    margin: 0px;
                                    color: white;
                                }

                                h5 {
                                    font-size: 14px;
                                    margin: 0px;
                                    color: white;
                                }

                                h6 {
                                    font-size: 30px;
                                    margin: 0px;
                                    color: white;
                                }

                                @media only screen and (min-width:500px) and (max-width:768px){
                                    h6 {
                                        font-size: 30px;
                                        margin: 0px;
                                        color: white;
                                    }
                                 }

                            }
                        }

                        &-btndiv {
                            width: 100%;
                            height: 9rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #FFFFFF;
                            @media only screen and (min-width:500px) and (max-width:768px){
                                height: 7rem;
                             }

                            button {
                                    width: 70%;
                                    margin: 0px auto;
                                    background-color: #485da1;
                                    border: none;
                                    padding: 10px;
                                    color: white;
                            }
                        }

                        &-socialviews {
                            width: 100%;
                            display: flex;
                           
                            border: 1px solid  #e1e1e1;

                            &-whislist {
                                    width: 70%;
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    padding: 15px;
                                    h6 {
                                        margin: 0px;
                                    }
                            }

                            &-views {
                                    width: 30%;
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    border-left: 1px solid #e1e1e1;
                                    padding: 15px;
                                    h6 {
                                        margin: 0px;
                                    }
                            }
                        }
                    }
                }
            }


            background-color: $bg-color-white;
            padding: 48px 0px;
            @media only screen and (max-width: 1100px) {
                padding: 35px 0px;
            }
            @media only screen and (max-width: 992px) {
                padding: 30px 0px;
            }
            @media only screen and (max-width: 767px) {
                padding: 22px 0px 15px;
            }
            .our-service-icon {
                margin-right: 15px;
                max-width: 30px;
                @media only screen and (max-width: 767px) {
                    margin-right: 8px !important;
                    max-width: 23px !important;
                }
                @media only screen and (max-width: 375px) {
                    margin-right: 8px;
                }
                @media only screen and (max-width: 340px) {
                    margin-bottom: 15px;
                    margin: auto;
                    text-align: center;
                }
                img {
                    @media only screen and (max-width: 375px) {
                        width: 25px;
                    }
                }
            }
        }
        .pckg-section {
            //background: rgba(17, 141, 255, 0.05);
            padding: 15px 0px 40px;
            @media only screen and (max-width: 767px) {
                padding: 15px 0px;
            }
            .packg-details-content {
                margin-top: 15px;
                @media only screen and (max-width: 767px) {
                    margin-top: 0px;
                }
                .package-include-services {
                    margin-top: 40px;
                    margin-bottom: 10px;
                    @media only screen and (max-width: 414px) {
                        margin-top: 10px;
                    }
                    .package-include-services-list {
                        display: flex;
                        flex-wrap: wrap;
                        .package-include-service-item {
                            display: flex;
                            align-items: center;
                            max-width: 20%;
                            width: 100%;
                            margin-bottom: 24px;
                            @media only screen and (max-width: 1200px) {
                                max-width: 30%;
                                margin-bottom: 16px;
                            }
                            @media only screen and (max-width: 992px) {
                                max-width: 50%;
                                margin-bottom: 16px;
                            }
                            @media only screen and (max-width: 320px) {
                                max-width: 100%;
                                margin-bottom: 10px;
                            }
                            .package-include-service-img {
                                height: 48px;
                                width: 48px;
                                background: rgba(17, 141, 255, 0.1);
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                overflow: hidden;
                                @media only screen and (max-width: 992px) {
                                    height: 35px;
                                    width: 35px;
                                }
                                @media only screen and (max-width: 767px) {
                                    height: 24px;
                                    width: 24px;
                                }
                                svg {
                                    @media only screen and (max-width: 992px) {
                                        width: 20px;
                                    }
                                    @media only screen and (max-width: 767px) {
                                        width: 13px;
                                    }
                                }
                            }
                            .package-include-service-content {
                                max-width: calc(100% - 70px);
                                width: 100%;
                                margin-left: 12px;
                                font-size: 14px;
                                font-weight: $font-weight-semibold;
                                @media only screen and (max-width: 992px) {
                                    font-size: 12px;
                                    max-width: calc(100% - 50px);
                                }
                                @media only screen and (max-width: 767px) {
                                    font-size: 10px;
                                    max-width: calc(100% - 36px);
                                }
                            }
                        }
                    }
                }
                ol {
                    padding-left: 20px;
                    li {
                        font-weight: 500;
                        font-size: 14px;
                        margin-bottom: 10px;
                        padding-left: 5px;
                        color: #000;
                        @media only screen and (max-width: 767px) {
                            font-size: 12px;
                            line-height: 22px;
                        }
                    }
                }
                ul {
                    list-style: none;
                    margin-bottom: 30px !important;
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 20px !important;
                    }
                    li {
                        margin-left: 10px;
                        padding-left: 20px;
                        position: relative;
                        color: $secondary-color;
                        font-weight: 500;
                        font-size: 14px;
                        margin-bottom: 10px;
                        font-size: 14px !important;
                        color: $secondary-color !important;
                        line-height: 21px !important;
                        font-family: Montserrat, sans-serif !important;
                        span {
                            color: $secondary-color !important;
                        }
                        @media only screen and (max-width: 767px) {
                            font-size: 12px;
                            line-height: 22px;
                            padding-left: 13px;
                        }
                        &::before {
                            content: '';
                            height: 7px;
                            width: 7px;
                            border-radius: 50%;
                            background-color: #000;
                            position: absolute;
                            left: 0;
                            top: 6px;
                            @media only screen and (max-width: 767px) {
                                height: 6px;
                                width: 6px;
                                top: 8px;
                            }
                            @media only screen and (max-width: 575px) {
                                height: 5px;
                                width: 5px;
                                top: 9px;
                            }
                        }
                    }
                }
                p {
                    font-size: 14px !important;
                    color: black !important;
                    line-height: 18px;
                    font-family: Montserrat, sans-serif !important;
                }
                .MsoNormal {
                    font-size: 16px !important;
                    color: #000 !important;
                    line-height: 21px !important;
                    font-family: Montserrat, sans-serif !important;
                    background: transparent !important;
                    text-indent: inherit !important;
                    margin: 10px 0px !important;
                }
                table {
                    width: 100% !important;
                    overflow-x: auto !important;
                    border: none;
                    tr {
                        td {
                            text-align: left !important;
                            padding: 8px !important;
                        }
                    }
                }
                a {
                    color: $primary-color !important;
                }
                div {
                    overflow: hidden;
                }
            }
            hr {
                opacity: 1;
                border: 1px solid #D0D0DA;
                margin-top: 30px;
                @media only screen and (max-width: 767px) {
                    margin-top: 24px;
                }
            }
            .sub-heading {
                margin-bottom: 10px;
                @media only screen and (max-width: 767px) {
                    margin-bottom: 0;

                    h5 {
                        margin: 0;
                    }
                }
            }
            .pckg-tabs-list {
                @media only screen and (max-width: 767px) {
                    //  display: none;
                }
            }
        }
        .testimonial-section {
            background-color: $bg-color-white;
            margin-bottom: 0px;
            @media only screen and (max-width: 992px) {
                margin-bottom: 30px;
            }
            @media only screen and (max-width: 600px) {
                margin-bottom: 15px;
            }
            .testimonial-item {
                @media only screen and (max-width: 600px) {
                    box-shadow: none;
                }
            }
        }
    }
}
.fixed {
    position: fixed;
    left: unset;
    right: 4rem;
    top: 134px;
    width: 46% !important;

    @media only screen and (min-width: 1920px) and (max-width: 2560px) {
        right: 40rem;
        width: 25% !important;
    }
    
    @media only screen and (min-width: 1440px) and (max-width: 1920px) {
        right: 18rem;
        width: 35% !important;
    }
    
    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
        right: 7rem;
        width: 43% !important;
    }
    
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        right: 3rem;
        width: 45% !important;
    }
    
    
}
.control-dots {
    display:none!important;
}
// test
// test


.standardfourdayspckg {
      

        

        &-para {
            padding-top: 10px;
            p {
                font-family: 'Montserrat';
                margin: 0;
                font-size: 17px;
            }
        }

        &-headingsdiv {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-top: 10px;

            h4 {
                margin:0;
                color: $primary-color;
                font-family: 'Montserrat';
            }
    
            p{
                margin:0;
                font-family: 'Montserrat';
                font-size: 16px;
            }
    

           
        }
}