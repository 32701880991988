// Variables Colors
$bg-gray:#EEF2F8;
$body-color:#1c2745;
 $primary-color: #118dff;
//$primary-color: #132F3B;
$secondary-color: #808396;
$error-color: #f00;
$bg-color-white:#fff;
$hr-color:#E8E6E6;
// variables Font Family 
$font-monteserrat: 'Montserrat', sans-serif;
$font-poppins: 'Poppins',
sans-serif;
// variables Font Size 
$body-font-size: 16px;
$H1:48px;
$H2:36px;
$H3: 30px;
$H4: 24px;
$H5: 20px;
$H6: 18px;
$font-size-20:20px;
$font-size-16:16px;
$font-size-14:14px;
$font-size-12:12px;
$font-size-10:10px;
// variables Font Weight
$font-weight-normal: 400;
$font-weight-500: 500;
$font-weight-bold: 700;
$font-weight-semibold:600;
$font-weight-blackbold:900;