.cfg-maindiv {
    //width: 70%;
    display: flex;
    flex-direction: column;
      border-radius: 20px;
      padding: 20px;
     background: #118dff;




    &-headings {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:10px;


      &-crossbtn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          cursor: pointer;
          border: 2px solid white;
          font-size: 14px;
          font-weight: bold;
          padding: 2px 8px;
          border-radius: 50px;
        }
      }

      h3 {
          font-size: 25px;
          color: #FFFFFF;
          margin: 0;
         @media (min-width: 768px) {
          font-size: 18px;
          }
        @media (max-width: 500px) {
          font-size: 16px;
        }
        @media (max-width: 375px) {
          font-size: 13px;
        }
      }

      p {
        color: #FFFFFF;
      }

    }

  &-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

      input {
          padding: 5px;
      }

        select {
          padding: 5px;
        }

      textarea {
        padding: 5px;
        resize: none;
      }

    &-btndiv {
        width: 100%;
        display: flex;

      button {
        background: #FFFFFF;
        padding: 10px;
        font-weight: 600;
        color: #118dff;
        text-transform:uppercase;
      }
    }
  }

}