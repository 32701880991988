
header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  background:white;
}

/* Mobile Header Container */
.text-highligh {
  display: none;
  text-align: center;
  padding: 20px 10px;
  background-color: #fff; /* Matches the header background */
}

/* Animated Heading */
.animated-heading {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #008000, #FFD700, #00C853);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: saudiFlagOnWhite 3s ease infinite;
}

/* Animation Keyframes */
@keyframes saudiFlagOnWhite {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Responsive Design for Smaller Devices */
@media screen and (max-width: 960px) {
  .text-highligh{
    display: block;
  }
  .animated-heading {
    font-size: 20px;
  }
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px 20px;
  gap: 55px;
}

.logo {
  text-decoration: none;
  //font-size: 25px;
  //color: inherit;
  //margin-right: 20px;


}
.logo img {
  width:6rem;
  @media only screen and (max-width: 768px) {
    width:5rem;
  }
}

.logo-set {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  
    img {
      @media only screen and (max-width: 768px) and (min-width: 428px) {
        width:30%;
      }
        width: 40%;
    }
}

/* menu on desktop */
.desktop-nav {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 2560px) and (min-width: 1920px) {
    width:84%;
  }
}

.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom:0;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: 'Montserrat';
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  border-radius: 10px;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  //background-color: #f2f2f2;
  background-image: linear-gradient(93deg, #53b2fe, #065af3) !important;
  color: white;
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
  0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 13rem;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  display: none;
}

.fixedtobottom {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
}

@media only screen and (max-width: 768px)  {
  #setFormobile {
    margin:12px auto;
    width:54%;
    border:4px dotted #118DFF;
     padding:12px;

    .header-contact {

      #bannerSetting {
          justify-content: space-between;
      }
    }
  }
  
  #parasetformobile {
      font-size: 11px;
  }
  
  #numberSetMobile {
      font-size: 20px;
  }
}

@media only screen and (max-width: 500px)  {
  #setFormobile {
    margin:12px auto;
    width:80%;
    border:4px dotted #118DFF;
     padding:12px;
  }

  #bannerSetting {
      justify-content: space-between;
  }
  
  #parasetformobile {
      font-size: 9px;
  }
  
  #numberSetMobile {
      font-size: 20px;
  }
}

@media only screen and (max-width: 425px)  {
  #setFormobile {
    margin:12px auto;
    width:85%;
    border:4px dotted #118DFF;
    padding:12px;
  }

  #bannerSetting {
    justify-content: space-between;
  }

  #parasetformobile {
    font-size: 9px;
  }

  #numberSetMobile {
    font-size: 20px;
  }
}

@media only screen and (max-width: 400px)  {
  #setFormobile {
    margin:12px auto;
    width:85%;
    border:4px dotted #118DFF;
     padding:10px;
  }
  
  #parasetformobile {
      font-size: 8px;
      font-weight: 600;
  }
  
  #numberSetMobile {
      font-size: 13px;
  }
}

@media only screen and (max-width: 320px)  {
  #setFormobile {
    margin:12px auto;
    width:88%;
    border:4px dotted #118DFF;
     padding:8px;
  }
  
  #parasetformobile {
      font-size: 7px;
      font-weight: 600;
  }
  
  #numberSetMobile {
      font-size: 13px;
  }
}


// @media only screen and (max-width: 2560px) {
//   .desktop-nav {
//     width:85%;
// }
// }



@media only screen and (max-width: 1440px) {
  .desktop-nav {
    width:79%;
}
}

@media only screen and (max-width: 1024px) {
  .nav-area {
      gap: 5px;
  }
  .desktop-nav {
      width: 80%;
  }
  .desktop-nav .menus {
      padding-left: 0;
  }
  .desktop-nav .menu-items {
      font-size: 14px;
  }
  .desktop-nav .menu-items > a, .desktop-nav .menu-items button {
      padding: 10px;
  }
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .nav-area {
    justify-content: space-between;
    //padding: 8px;
    padding: 8px 13px;
    gap: 0;
    &-logobanner {
        width:9rem;
    }
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }
  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }
  .mobile-nav .menus {
    width:100%;
    height: 100vh;
    height: 100dvh;
    list-style: none;
    position: static;
    top: 80px;
    right: 0;
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    font-family: 'Montserrat';
    font-weight: 600;
    // border-radius: 0.5rem;

    @media only screen and (max-width: 768px) and (min-width: 428px) {
      width:100%;
    }


  }

  nav.mobile-nav ul {
    // max-height: 52vh;
    // max-height: 52dvh;    
    // overflow-y: scroll;
}
.Parent_nav_menu {
  // max-height: 40vh;
  // min-height: 37vh;
  // height: 20vh;
  // height: 20dvh;

  // position: fixed;
  // width: 50vw;
  // bottom: -87vh;
  // bottom: -87dvh;
  // background: #ede8e8;
  // z-index: 99;
}

@media only screen and (min-width:280px) and (max-width:550px){

  .Parent_nav_menu{
    // width:64vw;
    // min-height: 44vh;
   
  }
  nav.mobile-nav ul {
    // max-height: 100vh;
    // height: 50.5vh !important;
    // overflow-y: scroll;
}
}

// @media only screen and (min-width:280px) and (max-width:550px) {
//   .Parent_nav_menu {
//     width: 64vw;
//     min-height: 39vh;
//     /* Override max-height and overflow-y for mobile view */
//     max-height: none;
//     overflow-y: visible;
//     position: fixed;
//     bottom: -87dvh;
//     bottom: -87vh; /* Reapply bottom position */
//     background: #ede8e8;
//     z-index: 99;
//     /* Add vendor prefixes for Safari */
//     -webkit-overflow-scrolling: touch; /* For smooth scrolling on Safari */
//   }
// }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    justify-content:space-between;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-image: linear-gradient(93deg, #53b2fe, #065af3) !important;
    color: white;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}

/* page content */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}



@media only screen and (min-width:280px) and (max-width:800px){
  .Mob_nav {
    min-height: 100%;
    position: absolute;
    width: 65%;
    right: 0;
    background: #fff;
    top: 96px;
    box-shadow: 2px -5px 20px #bcbcbc;
}

ul.menus {
    max-height: 50vh;
    overflow-y: scroll;
    /* top: 1vh !important; */
}

.Parent_nav_menu {
    bottom: -131vh;
    width: 100%;
    background: #fff;
}
}
 

div#fb-root {
  z-index: 0 !important;
  position: relative;
}

@media only screen and (min-width:768px) and (max-width:1024px){

  //.bottom-header-right{
  //  width: 37%!important;
  //}

  #smd {
    font-size: 23px!important;
  }
}

@media only screen and (min-width:1080px) and (max-width:1350px){

.contact-right.position-relative > p,a.react-reveal.font-weight-bold.mb-0.cursor-pointer>span  {
  font-size: 62% !important;
 
}
.bottom-header-right{
  position: relative;
  left: -27px;
}
}