@import "../../scss/variables.scss";
.page-packages {
    .package-services {
        background: #F6F6F6;
        padding: 50px 0px 30px 0px;
        @media only screen and (max-width: 992px) {
            padding: 30px 0px 0px 0px;
        }
        @media only screen and (max-width: 767px) {
            padding: 30px 0px 15px 0px;
        }
        @media only screen and (max-width: 575px) {
            padding: 25px 0px 10px 0px;
        }
        .our-services-list {
            margin-top: 30px;
            @media only screen and (max-width: 992px) {
                margin-top: 20px;
            }
            .our-service-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                &:last-child {
                    margin-right: 0px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 25px;
                }
                @media only screen and (max-width: 767px) {
                    margin-bottom: 15px;
                }
                @media only screen and (max-width: 340px) {
                    display: block;
                    margin: auto;
                    text-align: center;
                }
                .our-service-icon {
                    margin-right: 15px;
                    max-width: 30px;
                    @media only screen and (max-width: 375px) {
                        margin-right: 8px;
                    }
                    @media only screen and (max-width: 340px) {
                        margin-bottom: 15px;
                        margin: auto;
                        text-align: center;
                        margin-right: auto !important;
                    }
                    img {
                        @media only screen and (max-width: 375px) {
                            width: 25px;
                        }
                    }
                }
                p {
                    margin-bottom: 0px;
                    @media only screen and (max-width: 375px) {
                        font-size: 10px;
                    }
                    @media only screen and (max-width: 340px) {
                        margin: auto;
                        margin-bottom: 15px;
                        margin-top: 10px;
                        text-align: center !important;
                    }
                }
            }
        }
    }
    .pckg-section {
        @media only screen and (max-width: 992px) {
            padding-top: 30px;
        }
        .pckg-list {
            .pckg-item {
                display: flex;
                margin-bottom: 48px;
                @media only screen and (max-width: 992px) {
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 767px) {
                    display: block;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 6px;
                    padding: 12px;
                }
                @media only screen and (max-width: 414px) {
                    margin-bottom: 24px;
                }
                // &:last-child {
                //     margin-bottom: 30px;
                //     @media only screen and (max-width: 414px) {
                //         margin-bottom: 10px;
                //     }
                // }
                .pckg-img {
                    margin-right: 20px;
                    max-width: 335px;
                    width: 100%;
                    max-height: 250px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    object-fit: cover;
                    border-radius: 12px;
                    margin-bottom: 15px;
                    @media only screen and (max-width: 1200px) {
                        margin-right: 15px;
                    }
                    @media only screen and (max-width: 767px) {
                        margin-right: 0px;
                        max-width: 100%;
                    }
                    img {
                        max-width: 335px;
                        border-radius: 12px;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
                        @media only screen and (max-width: 767px) {
                            max-width: 100%;
                            width: 100%;
                            margin-bottom: 16px;
                            border-radius: 0px;
                        }
                    }
                }
                .pckg-content {
                    max-width: 810px;
                    width: 100%;
                    @media only screen and (min-width: 1600px) {
                        max-width: 810px;
                        width: 100%;
                    }
                    .packg-desc {
                        max-width: 810px;
                        width: 100%;
                        line-height: 28px;
                        margin-bottom: 16px;
                        @media only screen and (min-width: 1600px) {
                            max-width: 810px;
                            width: 100%;
                        }
                        @media only screen and (max-width: 992px) {
                            line-height: inherit;
                        }
                        @media only screen and (max-width: 767px) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        @media only screen and (max-width: 414px) {
                            -webkit-line-clamp: 2;
                        }
                    }
                    .pckg-date-price {
                        display: flex;
                        margin-bottom: 24px;
                        @media only screen and (max-width: 1400px) {
                            margin-bottom: 15px;
                        }
                        @media only screen and (max-width: 992px) {
                            display: block;
                        }
                        @media only screen and (max-width: 375px) {
                            svg {
                                height: 20px;
                                width: 20px;
                            }
                        }
                        h6 {
                            @media only screen and (max-width: 375px) {
                                margin-left: 6px !important;
                            }
                        }
                        .pck-date {
                            @media only screen and (max-width: 575px) {
                                margin-left: 10px !important;
                            }
                        }
                        .packg-price {
                            margin-left: 25px;
                            @media only screen and (max-width: 992px) {
                                margin-left: 0px;
                                margin-top: 15px;
                            }
                            @media only screen and (max-width: 575px) {
                                margin-left: 15px;
                            }
                            @media only screen and (max-width: 414px) {
                                margin-left: 0px;
                                margin-top: 10px;
                            }
                            h6 {
                                @media only screen and (max-width: 575px) {
                                    margin-left: 10px !important;
                                }
                                @media only screen and (max-width: 375px) {
                                    margin-left: 6px !important;
                                }
                            }
                        }
                    }
                    .btn-primary {
                        padding: 15px 35px;
                        font-size: 16px;
                        line-height: 20px;
                        &:hover {
                            border-color: transparent !important;
                        }
                        &:focus,
                        &:focus-visible {
                            color: $bg-color-white;
                            box-shadow: none !important;
                        }
                        @media only screen and (max-width: 767px) {
                            font-size: 14px;
                            line-height: inherit;
                            padding: 10px;
                        }
                        @media only screen and (max-width: 414px) {
                            margin-bottom: 12px;
                        }
                    }
                    hr {
                        border-color: #D0D0DA;
                        opacity: 1;
                        width: 100%;
                        @media only screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                    .pckg-tabs-list {
                        @media only screen and (max-width: 767px) {
                            display: none;
                        }
                        .pckg-tab-item {
                            background: rgba(17, 141, 255, 0.05);
                            border: 1px solid $primary-color;
                            border-radius: 6px;
                            cursor: pointer;
                            font-size: 12px;
                            line-height: 15px;
                            padding: 10px;
                            margin-right: 16px;
                            color: $primary-color;
                            font-weight: 400;
                            margin-bottom: 15px;
                            @media only screen and (max-width: 1200px) {
                                font-size: 11px;
                                line-height: 10px;
                                padding: 8px;
                                margin-right: 8px;
                            }
                            &:hover {
                                background-color: $primary-color;
                                color: $bg-color-white;
                            }
                        }
                    }
                }
            }
        }
    }
    .testimonial-section {
        background: rgba(17, 141, 255, 0.05);
        margin-bottom: 70px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 50px;
        }
        .testimonial-item {
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
            border-radius: 16px;
            padding: 24px 18px;
            @media only screen and (max-width: 767px) {
                padding: 15px 10px;
            }
            @media only screen and (max-width: 557px) {
                margin-bottom: 20px;
            }
            p {
                font-family: 'Poppins';
                font-weight: 500;
                font-style: italic;
                font-size: 16px;
                line-height: 32px;
                color: #808396;
                @media only screen and (max-width: 767px) {
                    text-align: justify;
                    font-size: 14px;
                    line-height: 25px;
                }
                @media only screen and (max-width: 575px) {
                    text-align: justify;
                    font-size: 12px;
                    line-height: 18px;
                }
                @media only screen and (max-width: 414px) {
                    text-align: center;
                }
            }
            .testimonial-user {
                font-family: 'Poppins';
                font-style: italic;
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                @media only screen and (max-width: 767px) {
                    text-align: justify;
                    font-size: 14px;
                    line-height: 25px;
                }
                @media only screen and (max-width: 575px) {
                    text-align: justify;
                    font-size: 12px;
                    line-height: 18px;
                }
                @media only screen and (max-width: 414px) {
                    text-align: center !important;
                }
            }
        }
    }
}

.fontsetonmbl {
    @media only screen and (max-width: 500px) {
         font-size: 1.2rem!important;
    }
}

.fortypemaindiv {
            display: flex;
            flex-direction: column;
    gap: 12px;

    &-fortypeumrah {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3 {
            margin:0;
            color: $primary-color;
            font-family: 'Montserrat';
        }

        p{
            margin:0;
            font-family: 'Montserrat';
            font-size: 16px;
        }

        &-unorderlist {
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                gap: 5px;

                li {
                   
                    h4 {
                        margin:0;
                        color: $primary-color;
                        font-size: 18px;
                        font-family: 'Montserrat';
                    }


                    p{
                        margin:0;
                        font-family: 'Montserrat';
                        font-size: 16px;
                    }
                }

              
        }
    }
}

